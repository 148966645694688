import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { useTheme } from '@mui/system'
import { object } from 'prop-types'
import Typography from '../../../atoms/typography'
import RichContent from '../../../particles/rich-content'
import { BottomAreaWrapper } from './bottom-area.styled'

const BottomArea = ({ copyrightProps, className }) => {
	const { copyright } = useConfig()
	const theme = useTheme()

	return (
		<BottomAreaWrapper className={cx('BottomArea-root', className)}>
			<Typography
				variant={'subtitle1'}
				sx={{
					color: theme.palette.common.white,
				}}
				component="div"
				{...copyrightProps}
			>
				<RichContent content={copyright} />
			</Typography>
		</BottomAreaWrapper>
	)
}

BottomArea.propTypes = {
	/**
	 * Defines the properties of copyright text
	 */
	copyrightProps: object,
}

export default BottomArea
