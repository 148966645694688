/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const MapPinIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12.1 0C7.1.1 3 4.1 3 9.2c0 5.2 4.8 10.5 8.1 14.2.3.3.7.6 1.2.6h.1c.5 0 .9-.3 1.2-.6 3-3.5 7.5-9.3 7.5-14.2S17.8 0 12.1 0zm.3 22.4c0 .1 0 .1-.1.1s-.1 0-.1-.1l-.4-.5c-3.1-3.5-7.3-8.4-7.3-12.8 0-4.2 3.4-7.6 7.6-7.7 5.1 0 7.4 3.8 7.4 7.7 0 3.4-2.4 7.9-7.1 13.3zM12 4.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5c0-2.4-2-4.5-4.5-4.5zm0 7.5c-1.7 0-3-1.4-3-3 0-1.7 1.4-3 3-3 1.7 0 3 1.4 3 3 0 1.7-1.3 3-3 3z" />
	</svg>
))
MapPinIconSvg.displayName = 'MapPinIconSvg'

const MapPinIcon = forwardRef((props, ref) => <Icon component={MapPinIconSvg} ref={ref} {...props} />)
MapPinIcon.displayName = 'MapPinIcon'

MapPinIcon.defaultProps = {
	...Icon.defaultProps,
}
MapPinIcon.propTypes = {
	...Icon.propTypes,
}

export default MapPinIcon
export { MapPinIconSvg }
