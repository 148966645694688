import { styled } from '@mui/system'

const Wrapper = styled('div')`
	.BHSocialWrapper {
		a {
			padding: 12px;
			margin: 0 5px;
			background: transparent;
			color: ${({ theme }) => theme.palette.footer.color};
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: 22px;
				margin: 0 10px;
			}
		}
	}
`

const SocialWrapper = styled('div')``

export { Wrapper, SocialWrapper }
