import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	min-height: 68px;
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	justify-content: center;
	text-align: center;
	padding: 0 25px;
	a {
		color: ${({ theme }) => theme.palette.common.white};
		text-decoration-color: ${({ theme }) => theme.palette.common.white};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 75px;
		justify-content: flex-start;
		text-align: left;
	}
`

export { BottomAreaWrapper }
